import React, {CSSProperties} from 'react';
import Box from '@material-ui/core/Box';
import {SectionTitle} from '../../uxui/SectionTitle';
import {ProductSlider} from '../components/ProductSlider';
import {ProductCore} from '../../core/models/ProductCore';

interface Props {
    title: string;
    products: ProductCore[];
    thumbStyle?: CSSProperties;
    desktopCols?: number;
    mobileCols?: number;
}

export const SectionProductSlider: React.FC<Props> = ({desktopCols, mobileCols, products, title, thumbStyle}) => {
    return (
        <Box>
            <SectionTitle title={title} />
            <ProductSlider
                products={products}
                thumbStyle={thumbStyle}
                desktopCols={desktopCols}
                mobileCols={mobileCols}
            />
        </Box>
    );
};
