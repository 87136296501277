import {QueryHookOptions, useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {CartInfoDetailedFragmentCS} from '../../core/fragments/apollo/CartInfoDetailedFragmentCS';

export const FETCH_CART = gql`
    query GetCartDetailed {
        cart {
            ...CartInfoDetailedFragmentCS
        }
    }
    ${CartInfoDetailedFragmentCS}
`;

export function useFetchCartDetailed(options?: QueryHookOptions) {
    const {loading, error, data} = useQuery(FETCH_CART, options);
    return {loading, error, cart: data && data.cart};
}
