import gql from 'graphql-tag';

export const ProductPriceInfoFragmentCS = gql`
    fragment ProductPriceInfoFragmentCS on Product {
        ... on VariableProduct {
            price
            dateOnSaleFrom
            dateOnSaleTo
            salePrice
            regularPrice
            onSale
        }
        ... on SimpleProduct {
            price
            dateOnSaleFrom
            dateOnSaleTo
            salePrice
            regularPrice
            onSale
        }
        ... on ExternalProduct {
            price
            dateOnSaleFrom
            dateOnSaleTo
            salePrice
            regularPrice
            onSale
        }
        ... on GroupProduct {
            dateOnSaleFrom
            dateOnSaleTo
            onSale
        }
    }
`;
