import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import {createStyles, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginRight: theme.spacing(1),
        },
        selected: {
            fontWeight: 'bold',
            borderWidth: 2,
            '&:hover': {
                borderWidth: 2,
            },
        },
    }),
);

interface Props {
    value: string;
    selected: boolean;
    onClick: (item: string) => void;
}

export const ProductVariationAttributeItem: React.FC<Props> = ({value, selected, onClick}) => {
    const onButtonClick = () => {
        if (!onClick) return;
        onClick(value);
    };

    const classes = useStyles();
    const color = selected ? 'primary' : 'default';
    // console.log('ProductVariationAttributeItem: ', item.name, selected);
    return (
        <Button
            className={classNames(classes.button, {[classes.selected]: selected})}
            variant={'outlined'}
            color={color}
            onClick={onButtonClick}
        >
            {value}
        </Button>
    );
};
