import gql from 'graphql-tag';
import {ShippingPackageInfoFragmentCS} from './ShippingPackageInfoFragmentCS';

export const CartInfoFragmentCS = gql`
    fragment CartInfoFragmentCS on Cart {
        contentsTax
        contentsTotal
        discountTax
        discountTotal
        displayPricesIncludeTax
        feeTax
        feeTotal
        isEmpty
        needsShippingAddress
        shippingTax
        shippingTotal
        subtotal
        subtotalTax
        total
        totalTax
        chosenShippingMethod
        availableShippingMethods {
            ...ShippingPackageInfoFragmentCS
        }
    }
    ${ShippingPackageInfoFragmentCS}
`;
