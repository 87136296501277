import {Cart} from '../models/Cart';
import {Product} from '../models/Product';
import {ProductCore} from '../models/ProductCore';
import {CartRemote} from '../models/CartRemote';

export function computeCartSubTotal(cart: Cart) {
    return cart.subtotal;
}

export function computeCartItemCount(cart: Cart) {
    return cart.cartItems ? cart.cartItems.reduce((acc, item) => acc + item.quantity, 0) : 0;
}

export function compactProductForCart(product: Product): ProductCore {
    const variations = product.variations
        ? product.variations.map(prodVar => {
              const tmpProdVar = {...prodVar};
              delete tmpProdVar.mainImage;
              return tmpProdVar;
          })
        : undefined;
    const newProduct = {...product, variations};
    delete newProduct.images;
    delete newProduct.mainImage;
    delete newProduct.relatedProducts;
    return newProduct;
}

export function cartHasShippingOptions(cart: CartRemote) {
    if (!cart || !cart.availableShippingMethods) return false;
    const methodWithRates = cart.availableShippingMethods.find(item => item.rates && item.rates.length > 0);
    return !!methodWithRates;
}
