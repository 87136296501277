import gql from 'graphql-tag';

export const ProductVariationInfoFragmentCS = gql`
    fragment ProductVariationInfoFragmentCS on ProductVariation {
        id
        variationId
        backordersAllowed
        description
        menuOrder
        name
        purchasable
        sku
        status
        type
        status
        manageStock
        stockQuantity
        price
        dateOnSaleFrom
        dateOnSaleTo
        backordersAllowed
        salePrice
        regularPrice
        onSale
        attributes {
            edges {
                node {
                    id
                    attributeId
                    name
                    value
                }
            }
        }
    }
`;
