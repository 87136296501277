import React from 'react';
import {Product} from '../../core/models/Product';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

interface Props {
    product: Product;
}

export const ProductDetailDescription: React.FC<Props> = ({product}) => {
    // Should only render if product has short and full description.
    if (!product.shortDescription) return null;
    return (
        <Box>
            <Typography variant="body1" dangerouslySetInnerHTML={{__html: product.description}} />
        </Box>
    );
};
