import gql from 'graphql-tag';

export const ShippingPackageInfoFragmentCS = gql`
    fragment ShippingPackageInfoFragmentCS on ShippingPackage {
        packageDetails
        rates {
            cost
            id
            instanceId
            label
            methodId
        }
        supportsShippingCalculator
    }
`;
