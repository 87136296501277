/* eslint-disable */
import React from 'react';
import {graphql} from 'gatsby';
import SEO from '../modules/layout/containers/SEO';
import _get from 'lodash/get';
import {Layout} from '../modules/layout/containers/Layout';
import {Product} from '../modules/core/models/Product';
import {mapNodeToProduct} from '../modules/core/mapper/ProductMapper';
import {ProductPageContent} from '../modules/product/containers/ProductPageContent';
import {useSiteMetadata} from '../modules/common/hooks/useSiteMetadata';
import {computeSEOMeta} from '../modules/core/util/SEOUtil';

interface Props {
    data: any;
    location: Location;
}

const ProductPageTemplate: React.FC<Props> = ({data, location}) => {
    const productInfo = _get(data, 'wpgraphql.product');
    const product: Product = mapNodeToProduct(productInfo);
    // FIXME - Integrate with WP Metadata
    const siteMetadata = useSiteMetadata();
    const {title, metadata} = computeSEOMeta(siteMetadata, productInfo.seo);
    return (
        <Layout location={location}>
            <SEO title={title} meta={metadata} />
            <ProductPageContent product={product} />
        </Layout>
    );
};

export default ProductPageTemplate;

export const pageQuery = graphql`
    query ProductPageQuery($id: ID!) {
        wpgraphql {
            product(id: $id) {
                ...ProductInfoFragment
                ...ProductSEOInfoFragment
                ...ProductAttrsFragment
                ...ProductVariationsFragment
                ...ProductRelatedProductsFragment
                ...ProductImageDetailFragment
            }
        }
    }
`;
