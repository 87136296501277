import React from 'react';
import {Product} from '../../core/models/Product';
import {ProductDetailDescription} from './ProductDetailDescription';

interface Props {
    product: Product;
}

export const ProductDetailOrTabs: React.FC<Props> = ({product}) => {
    // FIXME - Pending to validate if the product has tabs or is simple
    return <ProductDetailDescription product={product} />;
};
