import gql from 'graphql-tag';
import {ProductCoreInfoFragmentCS} from './ProductCoreInfoFragmentCS';
import {ProductPriceInfoFragmentCS} from './ProductPriceInfoFragmentCS';
import {ProductVariationInfoFragmentCS} from './ProductVariationInfoFragmentCS';

export const CartItemInfoFragmentCS = gql`
    fragment CartItemInfoFragmentCS on CartItem {
        key
        quantity
        subtotal
        subtotalTax
        tax
        total
        product {
            ...ProductCoreInfoFragmentCS
            ...ProductPriceInfoFragmentCS
        }
        variation {
            ...ProductVariationInfoFragmentCS
        }
    }
    ${ProductCoreInfoFragmentCS}
    ${ProductPriceInfoFragmentCS}
    ${ProductVariationInfoFragmentCS}
`;
