import gql from 'graphql-tag';

export const ProductCoreInfoFragmentCS = gql`
    fragment ProductCoreInfoFragmentCS on Product {
        id
        productId
        averageRating
        description
        featured
        menuOrder
        name
        purchasable
        reviewCount
        reviewsAllowed
        shortDescription
        sku
        slug
        status
        type
    }
`;
