import {MutationOptions, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {FETCH_CART} from './useFetchCartDetailed';
import {CartItemInfoFragmentCS} from '../../core/fragments/apollo/CartItemInfoFragmentCS';
import {CartInfoDetailedFragmentCS} from '../../core/fragments/apollo/CartInfoDetailedFragmentCS';
import {useSnackbar} from 'notistack';
import {CartItem} from '../../core/models/Cart';

const ADD_TO_CART = gql`
    mutation AddToCart($input: AddToCartInput!) {
        addToCart(input: $input) {
            clientMutationId
            cartItem {
                ...CartItemInfoFragmentCS
            }
            cart {
                ...CartInfoDetailedFragmentCS
            }
        }
    }
    ${CartInfoDetailedFragmentCS}
    ${CartItemInfoFragmentCS}
`;

export function useFetchAddToCart(options: Partial<MutationOptions>) {
    const {enqueueSnackbar} = useSnackbar();
    const onAddToCartComplete = (data: any) => {
        // console.log('onAddToCartComplete.data:', data);
        const cartItem: CartItem = data && data.addToCart && data.addToCart.cartItem;
        const prodName = cartItem.variation ? cartItem.variation.name : cartItem.product && cartItem.product.name;
        enqueueSnackbar(`${cartItem.quantity} x ${prodName} - Added to Cart`);
        if (options.onCompleted) options.onCompleted(data);
    };

    const [fetchAddToCart, {loading}] = useMutation(ADD_TO_CART, {
        ...options,
        update(cache, {data}) {
            const {
                addToCart: {cart},
            } = data;
            // console.log('useFetchUpdateCartItemQuantities.update:', cart);
            cache.writeQuery({
                query: FETCH_CART,
                data: {cart: cart},
            });
        },
        onCompleted: onAddToCartComplete,
    });
    return {fetchAddToCart, loading};
}
