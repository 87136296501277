import React from 'react';
import {ProductSpecific} from '../../core/models/ProductSpecific';
import {
    computeProductSpecificPriceFmt,
    computeProductSpecificRegularPriceFmt,
    isProdSpecificOnSale,
} from '../../core/util/ProductSpecificUtil';
import {BaseProductPriceLabel} from './BaseProductPriceLabel';

interface Props {
    product: ProductSpecific;
}

export const ProductSpecificPriceLabel: React.FC<Props> = ({product}) => {
    const isOnSale = isProdSpecificOnSale(product);
    const price = computeProductSpecificPriceFmt(product);
    const regPrice = computeProductSpecificRegularPriceFmt(product);
    return <BaseProductPriceLabel price={price} comparePrice={regPrice} onSale={isOnSale} />;
};
