import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {ProductVariationAttributeItem} from './ProductVariationAttributeItem';
import {ProductAttributeWithSelection} from '../../core/models/ProductAttributeWithSelection';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 'bold',
        },
        button: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface Props {
    variation: ProductAttributeWithSelection;
    onVariationItemClicked: (variation: ProductAttributeWithSelection, value: string) => void;
}

export const ProductVariationAttribute: React.FC<Props> = ({variation, onVariationItemClicked}) => {
    const classes = useStyles();

    const onItemClicked = (item: string) => {
        if (!onVariationItemClicked) return;
        onVariationItemClicked(variation, item);
    };

    const renderItems = (options: string[] | undefined) => {
        if (!options || options.length === 0) return null;
        return options.map((option: string) => (
            <ProductVariationAttributeItem
                key={`${variation.id}-${option}`}
                value={option}
                selected={option === variation.selectedOption}
                onClick={onItemClicked}
            />
        ));
    };
    const variationItems = renderItems(variation.options);
    return (
        <Box>
            <Typography className={classes.title} variant="subtitle1">
                {variation.name}
            </Typography>
            {variationItems}
        </Box>
    );
};
