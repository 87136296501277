/* eslint-disable camelcase */
import React, {CSSProperties} from 'react';
import Grid from '@material-ui/core/Grid';
import {ProductItem} from './ProductItem';
import {ProductCore} from '../../core/models/ProductCore';
import {computeBreakpoint} from '../../core/util/ResponsiveUtil';

interface Props {
    desktopCols?: number;
    mobileCols?: number;
    products: ProductCore[];
    thumbStyle?: CSSProperties;
}

export const ProductSlider: React.FC<Props> = ({desktopCols, mobileCols, products, thumbStyle}) => {
    const productItems = products.map((item: ProductCore) => {
        const path = `/product/${item.slug}/`;
        const key = item.id;
        const md = computeBreakpoint(desktopCols!);
        const xs = computeBreakpoint(mobileCols!);
        return (
            <Grid key={key} item xs={xs} md={md}>
                <ProductItem linkTo={path} product={item} thumbStyle={thumbStyle} />
            </Grid>
        );
    });
    return (
        <Grid container spacing={2}>
            {productItems}
        </Grid>
    );
};

ProductSlider.defaultProps = {
    desktopCols: 4,
    mobileCols: 1,
};
