import React from 'react';
import classNames from 'classnames';
import {ImageData} from '../../core/models/ImageData';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Box, {BoxProps} from '@material-ui/core/Box';
import Img from 'gatsby-image';

const useStyles = makeStyles(() =>
    createStyles({
        thumb: {
            display: 'flex',
            flexDirection: 'row',
            opacity: 0.5,
        },
        selected: {
            opacity: 1,
        },
    }),
);

interface Props extends BoxProps {
    image: ImageData;
    imageClassName?: string;
    selected: boolean;
    onThumbClick?: (img: ImageData) => void;
}

export const ProductImageThumb: React.FC<Props> = ({image, imageClassName, selected, onThumbClick, ...other}) => {
    const classes = useStyles();
    const onClick = () => {
        if (!onThumbClick) {
            return;
        }
        onThumbClick(image);
    };
    if (!image || !image.imageFile) {
        return null;
    }
    return (
        <Box {...other} onClick={onClick}>
            <Img
                key={image.id}
                className={classNames(classes.thumb, imageClassName, {[classes.selected]: selected})}
                fixed={image.imageFile.childImageSharp.fixed}
                fluid={image.imageFile.childImageSharp.fluid}
                alt={image.altText}
            />
        </Box>
    );
};
