import React from 'react';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {Colors} from '../../theme';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(2),
            borderBottom: `1px solid ${Colors.GRAY}`,
            marginBottom: theme.spacing(3),
            textTransform: 'uppercase',
        },
    }),
);

interface Props {
    title: string;
    textAlign?: string;
    className?: string;
    maxWidth?: Breakpoint;
}

export const SectionTitle: React.FC<Props> = ({className, maxWidth, title, textAlign}) => {
    const style: any = {textAlign};
    const classes = useStyles();
    return (
        <Container maxWidth={maxWidth} className={classNames(classes.wrapper, className)} style={style}>
            <Box>
                <Typography variant="h4" component="h2">
                    {title}
                </Typography>
            </Box>
        </Container>
    );
};

SectionTitle.defaultProps = {
    textAlign: 'center',
    maxWidth: 'md',
};
