import {ProductSpecific} from '../models/ProductSpecific';
import {ProductCore} from '../models/ProductCore';
import {ProductVariation} from '../models/ProductVariationOld';
import {ProductAttributeWithSelection} from '../models/ProductAttributeWithSelection';

export function findMainVariation(prodSpecific: ProductSpecific): ProductVariation | undefined {
    const {product, selectedVariation} = prodSpecific;
    if (selectedVariation) return selectedVariation;
    if (!product || !product.variations || product.variations.length === 0) return undefined;
    return product.variations[0];
}

export function computeProductSpecificId(productId: any, variationId: any): string {
    const variationStr = variationId ? `-${variationId.id}` : '';
    return `${productId}${variationStr}`;
}

export function computeProductSpecificName(prod: ProductSpecific): string {
    return prod.selectedVariation ? prod.selectedVariation.name : prod.product.name;
}

export function computeProductSpecificPrice(prod: ProductSpecific): number {
    return prod.selectedVariation ? prod.selectedVariation.meta.price : prod.product.meta.price;
}

export function computeProductSpecificRegularPrice(prod: ProductSpecific): number {
    return prod.selectedVariation ? prod.selectedVariation.meta.regularPrice : prod.product.meta.regularPrice;
}

export function computeProductSpecificPriceFmt(prod: ProductSpecific): string {
    return prod.selectedVariation ? prod.selectedVariation.meta.priceFmt : prod.product.meta.priceFmt;
}

export function computeProductSpecificRegularPriceFmt(prod: ProductSpecific): string {
    return prod.selectedVariation ? prod.selectedVariation.meta.regularPriceFmt : prod.product.meta.regularPriceFmt;
}

// function createSelectedVariation(variation: ProductVariationOld): SelectedProductVariation {
//     const selectedItem = variation.selectedItem
//         ? variation.selectedItem
//         : variation.items
//         ? variation.items[0]
//         : undefined;
//     return {
//         variation,
//         selectedItem,
//     };
// }

function findVariationByAttributeSelections(
    variations?: ProductVariation[],
    attrs?: ProductAttributeWithSelection[],
): ProductVariation | undefined {
    if (!variations || !attrs) return undefined;
    return variations.find(variation => {
        if (!variation.variationItemAttributes) return false;
        const matchArr = variation.variationItemAttributes.map(varItemAttr => {
            const attr = attrs.find(prodAttr => prodAttr.name.toLowerCase() === varItemAttr.name.toLowerCase());
            if (!attr) return false;
            return attr.selectedOption === varItemAttr.value;
        });
        return !matchArr.includes(false);
    });
}

export function createProductSpecificFromProdAndVariations(
    product: ProductCore,
    variationAttrs?: ProductAttributeWithSelection[],
): ProductSpecific {
    const selectedVariation = findVariationByAttributeSelections(product.variations, variationAttrs);
    const id = computeProductSpecificId(product.id, selectedVariation && selectedVariation.id);
    // console.log('createProductSpecificFromProd:', id, variationIds, selectedVariations);
    return {
        id,
        product,
        selectedVariation,
    };
}

export function isProdSpecificOnSale(prodSpecific: ProductSpecific) {
    const mainVariation = findMainVariation(prodSpecific);
    return mainVariation ? mainVariation.meta.onSale : prodSpecific.product.meta.onSale;
}
