import React from 'react';
import Img from 'gatsby-image';
import classNames from 'classnames';
import {ImageData} from '../../core/models/ImageData';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {ProductImageThumb} from './ProductImageThumb';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';

export enum ThumbPos {
    LEFT = 'left',
    RIGHT = 'right',
    BOTTOM = 'bottom',
}

const useStyles = makeStyles(() =>
    createStyles({
        mainImage: {
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'center',
            // minHeight: 550,
        },
        thumbsV: {
            // display: 'flex',
            // justifyContent: 'center',
        },
        responsive: {
            // maxWidth: '100%',
            // height: 'auto',
            // objectFit: 'contain',
        },
    }),
);

interface Props {
    images: ImageData[];
    currentImageIndx?: number;
    thumbsPosition?: ThumbPos;
    width: Breakpoint;
    onThumbClick: (img: ImageData) => void;
}

const _ProductImageGallery: React.FC<Props> = ({currentImageIndx, images, thumbsPosition, onThumbClick, width}) => {
    const classes = useStyles();
    if (!images || images.length === 0) {
        return null;
    }
    const isMobile = isWidthDown('sm', width);
    const finalThumbPosition = isMobile ? ThumbPos.BOTTOM : thumbsPosition;

    const currentImage = images[currentImageIndx as any];
    // const isVertical = finalThumbPosition === ThumbPos.BOTTOM;

    const thumbImages = images.map(item => (
        <Grid key={item.id} item xs={4} md={12}>
            <ProductImageThumb image={item} selected={item === currentImage} onThumbClick={onThumbClick} />
        </Grid>
    ));

    const mainImageSection = () => {
        // console.log('productMainImage:', currentImage.localFile);
        if (!currentImage || !currentImage.imageFile) return null;
        return (
            <Grid item xs={12} md={9} className={classNames(classes.mainImage, classes.responsive)}>
                <Img
                    fixed={currentImage.imageFile.childImageSharp.fixed}
                    fluid={currentImage.imageFile.childImageSharp.fluid}
                    alt={currentImage.altText}
                />
            </Grid>
        );
    };
    const thumbsSection = (
        <Grid item xs={12} md={3}>
            <Grid container>{thumbImages}</Grid>
        </Grid>
    );

    return (
        <Grid container>
            {finalThumbPosition === ThumbPos.LEFT ? thumbsSection : mainImageSection()}
            {finalThumbPosition === ThumbPos.LEFT ? mainImageSection() : thumbsSection}
        </Grid>
    );
};

_ProductImageGallery.defaultProps = {
    currentImageIndx: 0,
    thumbsPosition: ThumbPos.LEFT,
};

export const ProductImageGallery = withWidth()(_ProductImageGallery);
