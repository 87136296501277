import React from 'react';
import {ProductVariationAttribute} from './ProductVariationAttribute';
import {ProductAttributeWithSelection} from '../../core/models/ProductAttributeWithSelection';

interface Props {
    variations?: ProductAttributeWithSelection[];
    onVariationItemClicked: (variation: ProductAttributeWithSelection, item: string) => void;
}

export const ProductVariationList: React.FC<Props> = ({variations, onVariationItemClicked}) => {
    if (!variations || variations.length === 0) return null;

    const handleVariationItemClicked = (variation: ProductAttributeWithSelection, item: string) => {
        if (!onVariationItemClicked) return;
        onVariationItemClicked(variation, item);
    };

    const allVariationEl = variations.map(item => {
        if (!item.visible || !item.options || item.options.length === 0) return null;
        return (
            <ProductVariationAttribute
                key={item.id}
                variation={item}
                onVariationItemClicked={handleVariationItemClicked}
            />
        );
    });
    const filteredVariationEl: any = allVariationEl.filter(item => !!item);
    return <>{filteredVariationEl}</>;
};
