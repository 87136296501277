import React from 'react';
import {ProductInfoAttr} from '../../core/models/ProductCore';
import {Typography} from '@material-ui/core';
import _keys from 'lodash/keys';

interface Props {
    attributes?: ProductInfoAttr;
}

export const ProductInfoAttributes: React.FC<Props> = ({attributes}) => {
    if (!attributes) {
        return null;
    }
    const attrKeys = _keys(attributes);
    const attrsEL = attrKeys.map(key => {
        const value = attributes[key as any];
        return (
            <Typography variant="inherit" component="p" key={key}>
                {value}
            </Typography>
        );
    });
    return <>{attrsEL}</>;
};
