import React from 'react';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Box from '@material-ui/core/Box';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';
import {createStyles, makeStyles, Theme, styled} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {Colors} from '../../theme';

const StyledTextField = styled(TextField)(() => ({
    borderRadius: 0,
    margin: 0,
    fieldSet: {
        borderRadius: 0,
    },
    '& input:valid + fieldset': {
        margin: 0,
        borderRadius: 0,
    },
    '&:focus': {
        borderColor: Colors.BLACK,
    },
}));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `3px solid ${theme.palette.primary.main}`,
            borderRadius: theme.shape.borderRadius,
            '&.dense': {
                maxWidth: '12rem',
            },
        },
        input: {
            borderRadius: 0,
        },
        buttonLeft: {
            padding: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        buttonRight: {
            padding: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
    }),
);

type Props = TextFieldProps & {
    onSubstractClicked: () => void;
    onAddClicked: () => void;
};

export const InputStepper: React.FC<Props> = ({className, margin, onSubstractClicked, onAddClicked, ...other}) => {
    const classes = useStyles();
    const isDense = margin === 'dense';
    return (
        <Box className={classNames(className, classes.container, {dense: isDense})}>
            <IconButton
                size="medium"
                className={classes.buttonLeft}
                aria-label="substract one"
                onClick={onSubstractClicked}
            >
                <RemoveIcon fontSize="inherit" />
            </IconButton>
            <StyledTextField
                type="number"
                fullWidth
                margin={margin}
                inputProps={{
                    className: classes.input,
                }}
                variant={'outlined' as any}
                {...other}
            />
            <IconButton size="medium" className={classes.buttonRight} aria-label="add one" onClick={onAddClicked}>
                <AddIcon fontSize="inherit" />
            </IconButton>
        </Box>
    );
};

InputStepper.defaultProps = {
    type: 'number',
    variant: 'outlined',
};
