import React, {useState} from 'react';
// import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {InputStepper} from '../../uxui/InputStepper';
import {Product} from '../../core/models/Product';
import {normalizeQty} from '../../core/util/NumberUtil';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
        },
        input: {
            alignSelf: 'flex-start',
        },
        buttonLeft: {
            marginRight: theme.spacing(1),
        },
        row: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
    }),
);

interface Props {
    disabled?: boolean;
    product: Product;
    onAddToCart?: (product: Product, qty: number) => void;
    onBuyItNow?: (product: Product, qty: number) => void;
}

export const AddToCart: React.FC<Props> = ({disabled, product, onAddToCart, onBuyItNow}) => {
    const classes = useStyles();
    const [quantity, setQuantity] = useState(1);

    const onTextFieldChanged = (e: any) => setQuantity(normalizeQty(e.target.value) as any);
    const onAddClicked = () => setQuantity(isNaN(quantity) ? 0 : quantity + 1);
    const onSubstractClicked = () => setQuantity(isNaN(quantity) || quantity - 1 < 0 ? 0 : quantity - 1);

    const handleAddToCartClick = () => {
        if (quantity < 1) return;
        onAddToCart && onAddToCart(product, quantity);
    };
    const handleBuyItNowClick = () => {
        if (quantity < 1) return;
        onBuyItNow && onBuyItNow(product, quantity);
    };

    return (
        <div className={classes.container}>
            <Box className={classes.container}>
                <Typography variant="body1">QTY</Typography>
                <InputStepper
                    disabled={disabled}
                    className={classes.input}
                    hiddenLabel={true}
                    value={quantity}
                    margin="dense"
                    onAddClicked={onAddClicked}
                    onSubstractClicked={onSubstractClicked}
                    onChange={onTextFieldChanged}
                />
            </Box>
            <Box>
                <Button
                    disabled={disabled}
                    className={classes.buttonLeft}
                    variant="outlined"
                    color="primary"
                    size={'large'}
                    onClick={handleAddToCartClick}
                >
                    Add to Cart
                </Button>
                <Button
                    disabled={disabled}
                    variant="contained"
                    color="primary"
                    size={'large'}
                    onClick={handleBuyItNowClick}
                >
                    Buy it now
                </Button>
            </Box>
        </div>
    );
};

AddToCart.defaultProps = {
    disabled: false,
};
