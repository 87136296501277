import React, {useState} from 'react';
import {navigate} from 'gatsby';
import {Product} from '../../core/models/Product';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {ProductImageGallery} from '../components/ProductImageGallery';
import {ProductSummary} from '../components/ProductSummary';
import {AddToCart} from '../../cart/containers/AddToCart';
// import {SharePageSocialQuickbar} from '../../uxui/SharePageSocialQuickbar';
import {ProductDetailOrTabs} from '../components/ProductDetailOrTabs';
import {SectionProductSlider} from './SectionProductSlider';
import Container from '@material-ui/core/Container';
import {ImageData} from '../../core/models/ImageData';
import {ProductVariationList} from '../components/ProductVariationList';
import {compactProductForCart} from '../../core/util/CartUtil';
import {createProductSpecificFromProdAndVariations, isProdSpecificOnSale} from '../../core/util/ProductSpecificUtil';
import {NavLink} from '../../uxui/NavLink';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {OnSaleStamp} from '../components/OnSaleStamp';
import {ProductAttributeWithSelection} from '../../core/models/ProductAttributeWithSelection';
import {useFetchAddToCart} from '../../common/hooks/useFetchAddToCart';
import {AddToCartInput} from '../../core/models/mutations/AddToCartInput';
import {LoadingModal} from '../../uxui/LoadingModal';
import {productHasVariations} from '../../core/util/ProductUtil';
import {useCustomerMutationId} from '../../common/hooks/useCustomerMutationId';

interface Props {
    product: Product;
}

export const ProductPageContent: React.FC<Props> = ({product}) => {
    const clientMutationId = useCustomerMutationId();
    const [buyItNowRedirect, setBuyItNowRedirect] = useState(false);
    const [variationAttrs, setVariationsAttrs] = useState(product.variationAttributes);
    const [productSpecific, setProductSpecific] = useState(
        createProductSpecificFromProdAndVariations(product, product.variationAttributes),
    );
    const [selectedImgIndx, setSelectedImgIndx] = useState(product.images.indexOf(product.mainImage));
    const onImageClicked = (img: ImageData) => setSelectedImgIndx(product.images.indexOf(img));
    const onVariationItemClicked = (variation: ProductAttributeWithSelection, value: string) => {
        if (!variationAttrs) return;
        variation.selectedOption = value;
        setVariationsAttrs([...variationAttrs]); // Force variations render on update
        setProductSpecific(createProductSpecificFromProdAndVariations(product, variationAttrs));
        // console.log('specificVariation:', productSpecific);
        // Set variation image
        if (productSpecific.selectedVariation && productSpecific.selectedVariation.mainImage) {
            const itemImg = productSpecific.selectedVariation.mainImage;
            const indx = product.images.findIndex(img => img.id === itemImg.id || img.src === itemImg.src);
            if (indx > -1) {
                setSelectedImgIndx(indx);
            }
        }
    };

    const onAddToCartCompleted = (_data: any) => {
        console.log('onAddToCartCompleted...', buyItNowRedirect);
        if (buyItNowRedirect) navigate('checkout');
    };
    const {fetchAddToCart, loading} = useFetchAddToCart({onCompleted: onAddToCartCompleted});

    const onAddToCartClicked = (product: Product, qty: number) => {
        const compactProduct = compactProductForCart(product);
        const compactProductSpecific = createProductSpecificFromProdAndVariations(compactProduct, variationAttrs);
        const input: AddToCartInput = {
            clientMutationId,
            productId: product.productId,
            quantity: qty,
            variationId:
                compactProductSpecific &&
                compactProductSpecific.selectedVariation &&
                compactProductSpecific.selectedVariation.variationId,
        };
        fetchAddToCart({variables: {input}});
    };
    const onBuyItNowClicked = (product: Product, qty: number) => {
        const compactProduct = compactProductForCart(product);
        const compactProductSpecific = createProductSpecificFromProdAndVariations(compactProduct, variationAttrs);
        const input: AddToCartInput = {
            clientMutationId,
            productId: product.productId,
            quantity: qty,
            variationId:
                compactProductSpecific &&
                compactProductSpecific.selectedVariation &&
                compactProductSpecific.selectedVariation.variationId,
        };
        setBuyItNowRedirect(true);
        fetchAddToCart({variables: {input}});
    };

    const isOnSale = isProdSpecificOnSale(productSpecific);

    const relatedProduct = product.relatedProducts;
    const relatedProductSlice =
        relatedProduct && relatedProduct.length > 4 ? relatedProduct.slice(0, 4) : relatedProduct;
    const relatedProductsSection = relatedProductSlice ? (
        <Grid item xs={12}>
            <SectionProductSlider
                title="Related Products"
                products={relatedProductSlice}
                thumbStyle={{maxWidth: 250, maxHeight: 250}}
                mobileCols={2}
            />
        </Grid>
    ) : null;
    const isAddToCartEnabled = !productHasVariations(product) || (productSpecific && productSpecific.selectedVariation);
    console.log('isAddToCartEnabled:', isAddToCartEnabled, productHasVariations(product));

    return (
        <Container maxWidth="lg">
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <NavLink to={'/'} style={{color: 'inherit'}}>
                            Home
                        </NavLink>
                        <NavLink color="inherit" to={'/collections/all'} style={{color: 'inherit'}}>
                            Products
                        </NavLink>
                        <Typography color="textPrimary" style={{fontSize: 'inherit'}}>
                            {product.name}
                        </Typography>
                    </Breadcrumbs>
                    <ProductImageGallery
                        images={product.images}
                        currentImageIndx={selectedImgIndx}
                        onThumbClick={onImageClicked}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {isOnSale && (
                        <Box mb={1}>
                            <OnSaleStamp badgeMode={false} />
                        </Box>
                    )}
                    <ProductSummary productSpecific={productSpecific} />
                    <ProductVariationList variations={variationAttrs} onVariationItemClicked={onVariationItemClicked} />
                    <AddToCart
                        disabled={!isAddToCartEnabled}
                        product={product}
                        onAddToCart={onAddToCartClicked}
                        onBuyItNow={onBuyItNowClicked}
                    />
                    {/*<SharePageSocialQuickbar />*/}
                    <ProductDetailOrTabs product={product} />
                </Grid>
            </Grid>
            {relatedProductsSection}
            <LoadingModal open={loading} />
        </Container>
    );
};
