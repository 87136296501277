export function normalizeQty(qty: any, forceNumber = false) {
    if (!forceNumber && qty === '') {
        return '';
    }
    const tmpQty = Number(qty);
    return isNaN(tmpQty) ? 1 : tmpQty;
}

export function roundQty(qty: number) {
    return Math.round(qty * 100) / 100;
}
