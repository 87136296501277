import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {Product} from '../../core/models/Product';
import {SectionTitle} from '../../uxui/SectionTitle';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {ProductInfoAttributes} from './ProductInfoAttributes';
import {ProductSpecific} from '../../core/models/ProductSpecific';
import {ProductSpecificPriceLabel} from './ProductSpecificPriceLabel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginBottom: theme.spacing(3),
        },
        title: {
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
        },
        vendor: {
            marginTop: theme.spacing(2),
            color: theme.palette.primary.light,
            textTransform: 'uppercase',
        },
        price: {},
        attrs: {
            color: theme.palette.primary.light,
            marginBottom: theme.spacing(2),
        },
    }),
);

interface Props {
    productSpecific: ProductSpecific;
}

export const ProductSummary: React.FC<Props> = ({productSpecific}) => {
    const classes = useStyles();
    const prod = productSpecific.product as Product;
    const desc = prod.shortDescription ? prod.shortDescription : prod.description;
    return (
        <Box className={classes.container}>
            <SectionTitle title={prod.name} className={classes.title} textAlign="left" />
            <Typography className={classes.vendor} variant="body2">
                {prod.vendor}
            </Typography>
            <Typography className={classes.price} variant="h6">
                <ProductSpecificPriceLabel product={productSpecific} />
            </Typography>
            <Typography className={classes.attrs} variant="body2" component="div">
                <ProductInfoAttributes attributes={prod.infoAttributes} />
            </Typography>
            <Typography variant="body1" dangerouslySetInnerHTML={{__html: desc}} />
        </Box>
    );
};
