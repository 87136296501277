import gql from 'graphql-tag';
import {CartInfoFragmentCS} from './CartInfoFragmentCS';
import {CartItemInfoFragmentCS} from './CartItemInfoFragmentCS';

export const CartInfoDetailedFragmentCS = gql`
    fragment CartInfoDetailedFragmentCS on Cart {
        ...CartInfoFragmentCS
        contents {
            edges {
                node {
                    ...CartItemInfoFragmentCS
                }
            }
        }
    }
    ${CartInfoFragmentCS}
    ${CartItemInfoFragmentCS}
`;
