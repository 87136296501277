import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(3),
        },
    }),
);

interface Props {
    open: boolean;
    hideBackdrop?: boolean;
}

export const LoadingModal: React.FC<Props> = ({open, hideBackdrop}) => {
    const classes = useStyles();
    return (
        <Dialog
            fullScreen={false}
            open={open}
            className="loading-dialog"
            hideBackdrop={hideBackdrop}
            PaperProps={{elevation: hideBackdrop ? 1 : 2}}
            aria-labelledby="Progress"
        >
            <DialogContent className={classes.dialogContent}>
                <CircularProgress className="circular-progress" />
            </DialogContent>
        </Dialog>
    );
};
